import { CircularProgress } from "@mui/material";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { useEffect, useState } from "react";
import MonogramBlack from "../assets/MonogramBlack.png";
import { Home } from "./Home";
import StyledFirebaseAuth from "./StyledFirebaseAuth";

const uiConfig = {
  signInFlow: "popup",
  signInOptions: [
    {
      provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
    },
  ],
} as any;

if (process.env.REACT_APP_SIGNUP_DISABLED === "true") {
  uiConfig.signInOptions[0]["disableSignUp"] = {
    status: true,
    helpLink: "https://cloud.google.com/solutions/manufacturing-data-engine",
  };
}

function App() {
  const [isSignedIn, setIsSignedIn] = useState(false); // Local signed-in state.
  const [isLoading, setIsLoading] = useState(true); // Local signed-in state.

  // Listen to the Firebase Auth state and set the local state.
  useEffect(() => {
    const unregisterAuthObserver = firebase
      .auth()
      .onAuthStateChanged((user) => {
        setTimeout(() => setIsLoading(false), 200);
        setIsSignedIn(!!user);
      });
    return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
  }, []);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          height: "100vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </div>
    );
  } else {
    if (!isSignedIn) {
      return (
        <>
          <div
            style={{
              position: "absolute",
              display: "flex",
              height: "100%",
              alignItems: "flex-end",
            }}
          >
            <img
              src={MonogramBlack}
              alt="Logo"
              style={{
                maxWidth: "80px",
                marginBottom: "24px",
                marginLeft: "18px",
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              height: "100vh",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "100%",
              }}
            >
              <StyledFirebaseAuth
                uiConfig={uiConfig}
                firebaseAuth={firebase.auth()}
              />
            </div>
          </div>
        </>
      );
    }
    return <Home />;
  }
}

export default App;
