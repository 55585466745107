import { Box, Tab, Tabs } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Page, Section } from "./Home";
import { EmbeddedLookerDashboard } from "./tabs/EmbeddedLookerDashboard";

export const TabbedPage = ({
  section,
  section: { pages },
}: {
  section: Section;
}) => {
  const navigate = useNavigate();
  let { path } = useParams();
  const [page, setPage] = useState<Page>();
  useEffect(() => {
    setPage(pages?.find((page) => page.path === path));
  }, [path, pages]);

  return (
    <>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          zIndex: "999",
          backgroundColor: (theme) =>
            theme.palette.mode === "light" ? "white" : theme.palette.grey[900],
          width: "100%",
        }}
      >
        <Tabs
          variant="scrollable"
          onChange={(_e, pagePath) => {
            navigate(`/${section.path}/${pagePath}`);
          }}
          value={path}
        >
          {pages.map((page) => (
            <Tab key={page.path} label={page.title} value={page.path} />
          ))}
        </Tabs>
      </Box>
      <>
        {page?.type === "looker-dashboard" && page?.dashboardId && (
          <EmbeddedLookerDashboard dashboardId={page?.dashboardId} />
        )}
        {page?.type === "vf-iframe" ? (
          <iframe
            style={{ border: 0 }}
            title="Virtual Factory"
            src="https://virtual-factory.cedemo.mde.manufacturing.joonix.net/ui"
          />
        ) : null}
      </>
    </>
  );
};
