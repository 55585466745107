import { createTheme, ThemeProvider } from "@mui/material";
import GlobalStyles from "@mui/material/GlobalStyles";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { Fragment } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./components/App";
import "./firebaseui-styling.global.css";
import reportWebVitals from "./reportWebVitals";

const config = {
  apiKey: process.env.REACT_APP_IDENTITY_PLATFORM_API_KEY,
  authDomain: process.env.REACT_APP_IDENTITY_PLATFORM_AUTH_DOMAIN,
};
firebase.initializeApp(config);

const mdTheme = createTheme({
  palette: {
    primary: {
      main: "#000000",
    },
    secondary: {
      main: "#98917D",
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root") as Element);
root.render(
  <Fragment>
    <GlobalStyles
      styles={{
        iframe: {
          height: "86vh",
          width: "100%",
        },
        "#firebaseui_container": {
          flex: "12",
        },
      }}
    />
    <BrowserRouter>
      <ThemeProvider theme={mdTheme}>
        <App />
      </ThemeProvider>
    </BrowserRouter>
  </Fragment>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
