import { BarChartOutlined, Logout } from "@mui/icons-material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import DvrIcon from "@mui/icons-material/Dvr";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import FactoryIcon from "@mui/icons-material/Factory";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsIcon from "@mui/icons-material/Notifications";
import LineChart from "@mui/icons-material/StackedLineChart";
import TuneIcon from "@mui/icons-material/Tune";
import {
  Grid,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  SvgIconProps,
  SvgIconTypeMap,
  Typography,
} from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import Toolbar from "@mui/material/Toolbar";
import { styled } from "@mui/material/styles";
import firebase from "firebase/compat/app";
import { Fragment, useState } from "react";
import {
  Link as LinkRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import WordmarkWhite from "../assets/WordmarkWhite.png";
import { TabbedPage } from "./TabbedPage";

export type Page = {
  title: string;
  dashboardId: string;
  path: string;
  type: "looker-dashboard" | "iframe" | "vf-iframe";
};

export type Section = {
  name: string;
  path: string;
  pages: Array<Page>;
};

const sections = JSON.parse(
  process.env.REACT_APP_DASHBOARDS as string
) as Array<Section>;

const Icon = (
  props: {
    path: string;
  } & SvgIconProps
) => {
  const sectionToIconMap: {
    [key: string]: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
  } = {
    sap: DvrIcon,
    "default-package": LineChart,
    "virtual-factory": TuneIcon,
    "anomaly-detection": ErrorOutlineIcon,
    "factory-overview": FactoryIcon,
  };
  const PathSpecificIcon = sectionToIconMap[props.path];

  return PathSpecificIcon ? (
    <PathSpecificIcon {...props} />
  ) : (
    <BarChartOutlined {...props} />
  );
};

const drawerWidth: number = 240;
interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

export const Home = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="absolute" open={isDrawerOpen}>
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                ...(isDrawerOpen && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <div
              style={{
                display: "flex",
                flex: 1,
              }}
            >
              <img
                src={WordmarkWhite}
                alt="Logo"
                style={{
                  maxWidth: "120px",
                }}
              />
            </div>
            <IconButton color="inherit">
              <Badge badgeContent={4} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <IconButton
              color="inherit"
              onClick={() => {
                firebase.auth().signOut();
              }}
            >
              <Logout />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={isDrawerOpen}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
          >
            <Typography
              component="h2"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1, ml: "16px" }}
            >
              Menu
            </Typography>
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            <Fragment>
              <Grid container direction="row">
                {isDrawerOpen
                  ? sections.map((section) => (
                      <Grid
                        key={`${section.path}-${isDrawerOpen}`}
                        item
                        xs={12}
                      >
                        <MenuList>
                          <MenuItem
                            selected={
                              location.pathname.split("/")[1] === section.path
                            }
                            onClick={() =>
                              navigate(
                                `/${section.path}/${section.pages[0].path}`
                              )
                            }
                          >
                            <ListItemIcon>
                              <Icon path={section.path} />
                            </ListItemIcon>
                            <ListItemText>{section.name}</ListItemText>
                          </MenuItem>
                        </MenuList>
                      </Grid>
                    ))
                  : sections.map((section) => (
                      <Grid
                        key={`${section.path}-${isDrawerOpen}`}
                        item
                        mt={2}
                        width="100%"
                      >
                        <Box display="flex" justifyContent="center">
                          <LinkRouter
                            to={`/${section.path}/${section.pages[0].path}`}
                          >
                            <Icon
                              path={section.path}
                              color={
                                location.pathname.split("/")[1] === section.path
                                  ? "primary"
                                  : "disabled"
                              }
                            />
                          </LinkRouter>
                        </Box>
                      </Grid>
                    ))}
              </Grid>
            </Fragment>
            <Divider sx={{ my: 1 }} />
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            width: "80%",
            flexGrow: 1,
            height: "100vh",
          }}
        >
          <Toolbar />
          <Routes>
            {sections.map((section) => (
              <Fragment key={section.path}>
                <Route
                  path={`/${section.path}`}
                  element={
                    <Navigate
                      replace
                      to={`/${section.path}/${section.pages[0].path}`}
                    />
                  }
                />
                <Route
                  path={`${section.path}/:path`}
                  element={<TabbedPage section={section} />}
                />
              </Fragment>
            ))}
            <Route
              path="/"
              element={
                <Navigate
                  replace
                  to={`/${sections[0].path}/${sections[0].pages[0].path}`}
                />
              }
            />
          </Routes>
        </Box>
      </Box>
    </>
  );
};
