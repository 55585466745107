import { LookerEmbedSDK } from "@looker/embed-sdk";
import { CircularProgress, Container, Grid } from "@mui/material";
import firebase from "firebase/compat/app";
import { useCallback, useEffect, useRef, useState } from "react";

type EmbeddedLookerDashboardProps = {
  dashboardId: string;
};

export const EmbeddedLookerDashboard = ({
  dashboardId,
}: EmbeddedLookerDashboardProps) => {
  const divRef = useRef<HTMLDivElement>(null);
  const dashboardRef = useRef<any | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const createDashboard = useCallback(() => {
    setIsLoading(true);
    firebase
      .auth()
      .currentUser?.getIdToken(true)
      .then((token) => {
        LookerEmbedSDK.init("imde.cloud.looker.com", {
          url: `${process.env.REACT_APP_API_HOST}/api/looker/url`,
          headers: [{ name: "Authorization", value: "Bearer " + token }],
        });
        LookerEmbedSDK.createDashboardWithId(dashboardId)
          .appendTo(divRef.current as HTMLElement)
          .withTheme("embed_theme")
          .build()
          .connect()
          .then((dashboard) => {
            dashboard.run();
            dashboardRef.current = dashboard;
            setTimeout(() => setIsLoading(false), 1000);
          });
      });
  }, [dashboardId]);

  useEffect(() => {
    while (divRef?.current?.firstChild) {
      divRef.current.lastChild &&
        divRef.current.removeChild(divRef.current.lastChild);
    }
    while (dashboardRef?.current?.firstChild) {
      dashboardRef.current.lastChild &&
        dashboardRef.current.removeChild(dashboardRef.current.lastChild);
    }

    createDashboard();
  }, [createDashboard]);

  return (
    <Container maxWidth={false} sx={{ mb: 4 }}>
      <Grid>
        <Grid item xs={12}>
          {isLoading ? (
            <Container
              sx={{
                mt: 4,
                mb: 4,
                height: "80vh",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress size={60} />
            </Container>
          ) : null}
          <div
            id={dashboardId}
            style={{ display: isLoading ? "none" : "block" }}
            ref={divRef}
          ></div>
        </Grid>
      </Grid>
    </Container>
  );
};
